import { defineStore } from "pinia";
import {
  useErrorStore,
  useCustomerStore,
  useNotificationStore,
} from "@/stores";
import { ref } from "vue";

const storeId = "google-user-store";

// SERVICES
import { GoogleWorkspaceUserService } from "@/apis/app-management/services/GoogleWorkspaceUserService";

export const useGoogleUserStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const customerStore = useCustomerStore();
  const notificationsStore = useNotificationStore();

  const items = ref([]);
  const query = ref(null);
  const loading = ref(false);

  // ===================== //
  // ======= LIST ======== //
  // ===================== //

  const addFilter = (filter) => {
    query.value = filter.value;
  };

  const removeFilter = () => {
    query.value = null;
  };

  const listItems = errorStore.withErrorHandling(
    async () => {
      try {
        loading.value = true;
        if (!query.value) {
          items.value = [];
          return [];
        }
        const res = await GoogleWorkspaceUserService.searchGoogleUsers(
          customerStore.activeCustomerId,
          query.value
        );

        // check which text matches the query.value
        // set result in a new key matchedText
        // it should use name.fullName, primaryEmail
        //  or emails and stop at the first match

        const queryLower = query.value.toLowerCase();

        res.users = res.users.map((user) => {
          let matchedText = "";
          if (user.name?.fullName?.toLowerCase().includes(queryLower)) {
            matchedText = user.name.fullName;
          } else if (user.primaryEmail?.toLowerCase().includes(queryLower)) {
            matchedText = user.primaryEmail;
          } else {
            const email = user.emails?.find((email) =>
              email.address?.toLowerCase().includes(queryLower)
            );
            if (email) {
              matchedText = email.address;
            }
          }
          return {
            ...user,
            matchedText,
          };
        });

        items.value = res.users;

        return res.users;
      } catch (error) {
        if (error.status === 412) {
          notificationsStore.showToast(
            "To search for users in your Google Workspace, you must first set the required scopes.",
            "warning"
          );
        }
      } finally {
        loading.value = false;
      }
    },
    storeId,
    "list",
    "Failed to list user",
    [412]
  );

  return {
    items,
    listItems,
    loading,
    addFilter,
    removeFilter,
  };
});
