import { defineStore } from "pinia";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { BigQueryJobsService, ReportsService } from "@/apis/security-auditing";
import { ExportService } from "@/apis/security-auditing/services/ExportService";
import { useErrorStore } from "@/stores";

const storeId = "shared-with-users-store";

export const useSharedWithUsersStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();

  const errorMessages = {
    listItems: `Failed to retrieve your top shares with users. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve your top shares with users. ${errorStore.defaultErrorMessage}`,
    exportData: `Failed to export your shares with users. ${errorStore.defaultErrorMessage}`,
  };

  const services = {
    listItemsService: ReportsService.listSharedWithUsers,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
    exportDataService: ReportsService.listSharedWithUsers,
    getExportJobStatusService: ExportService.getExport,
  };

  const returnObjectKey = "rows";
  const listServiceParams = [
    "customerId",
    "exportFormat",
    "exportMapping",
    "query",
    "sortBy",
    "sortOrder",
  ];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = {
    id: "orgUnitId",
  };

  const useIncludeOrgUnitsFilter = {
    id: "includeSubOrgUnits",
  };

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,

    // general
    resetStore,
    reset,
    loading,

    // exports
    exportData,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    useIncludeOrgUnitsFilter,
    errorMessages
  );

  return {
    // filters
    activeFilters,
    activeAdvancedFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // export
    exportData,
  };
});
