import { defineStore } from "pinia";
import { SharedDrivesService } from "@/apis/security-auditing/services/SharedDrivesService";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { ExportService } from "@/apis/security-auditing/services/ExportService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import {
  useNotificationStore,
  useErrorStore,
  useCustomerStore,
} from "@/stores";

const storeId = "shared-drive-store";

export const useSharedDriveStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const notificationStore = useNotificationStore();
  const customerStore = useCustomerStore();
  const errorStore = useErrorStore();

  const services = {
    listItemsService: SharedDrivesService.listSharedDrives,
    getItemService: SharedDrivesService.getSharedDrive,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
    updateItemService: SharedDrivesService.updateSharedDrive,
    exportDataService: SharedDrivesService.listSharedDrives,
    getExportJobStatusService: ExportService.getExport,
  };

  const errorMessages = {
    listItems: `Failed to retrieve your shared drives. ${errorStore.defaultErrorMessage}`,
    getItem: `Failed to retrieve a shared drive. ${errorStore.defaultErrorMessage}`,
    updateItem: `Failed to update a shared drive. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve your shared drives. ${errorStore.defaultErrorMessage}`,
    exportData: `Failed to export your shared drives. ${errorStore.defaultErrorMessage}`,
  };

  const returnObjectKey = "rows";
  const listServiceParams = [
    "customerId",
    "exportFormat",
    "exportMapping",
    "query",
    "sortBy",
    "sortOrder",
  ];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = {
    id: "orgUnit.orgUnitId",
  };

  const getItem = errorStore.withErrorHandling(
    async (sharedDriveId) => {
      const sharedDrive = await services.getItemService(
        customerStore.activeCustomerId,
        sharedDriveId
      );

      const sharedDriveDetails = sharedDrive.realtimeSharedDrive || {
        realTimeSharedDriveNotRetrievable: true,
      };

      // add missing properties from storedSharedDrive
      Object.keys(sharedDrive.storedSharedDrive).forEach((key) => {
        if (!sharedDriveDetails[key] && sharedDrive.storedSharedDrive[key]) {
          sharedDriveDetails[key] = sharedDrive.storedSharedDrive[key];
        }
      });

      // loop through fileDetails.userProperties check if fileDetails.permissions in fileDetails.userProperties.permission
      // overwrite fileDetails.userProperties.permission with entry in fileDetails.permissions array if id matches
      // so make sure we always have the latest permission data icm with the userProperties
      let removedPermissionIds = [];
      if (!sharedDriveDetails.permissions) {
        sharedDriveDetails.permissions = [];
      }

      if (!sharedDrive.realtimeSharedDrive) {
        sharedDriveDetails.userProperties.forEach((userProp) => {
          sharedDriveDetails.permissions.push(userProp.permission);
        });
      }

      if (
        sharedDriveDetails.userProperties &&
        sharedDrive.realtimeSharedDrive
      ) {
        sharedDriveDetails.userProperties.forEach((userProp, index) => {
          const permissionIndex = sharedDriveDetails.permissions.findIndex(
            (p) => p.id === userProp.permission.id
          );

          if (permissionIndex !== -1) {
            sharedDriveDetails.userProperties[index].permission =
              sharedDriveDetails.permissions[permissionIndex];
          } else {
            removedPermissionIds.push(userProp.permission.id);
          }
        });

        // remove permissions that are not in the permissions array
        sharedDriveDetails.userProperties =
          sharedDriveDetails.userProperties.filter(
            (userProp) => !removedPermissionIds.includes(userProp.permission.id)
          );
      }

      // add missing properties from permissions to userProperties
      if (sharedDriveDetails.permissions) {
        sharedDriveDetails.permissions.forEach((permission) => {
          const userPropIndex = sharedDriveDetails.userProperties.findIndex(
            (userProp) => userProp.permission.id === permission.id
          );

          if (userPropIndex === -1) {
            sharedDriveDetails.userProperties.push({
              fileId: sharedDriveDetails.id,
              facts: {}, // TODO: we have a separate api call get this information from the backend, ignored for now
              permission: permission,
            });
          }
        });
      }

      return sharedDriveDetails;
    },
    storeId,
    "get",
    "Failed to retrieve shared drive"
  );

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    // list
    items,
    listItems,
    totalItems,
    pageToken,
    // general
    resetStore,
    reset,
    loading,
    // crud
    // getItem,
    updateItem,

    // exports
    exportData,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    null,
    errorMessages
  );

  const updateFlbSharedDriveSettings = errorStore.withErrorHandling(
    async (sharedDriveId, { excludeFromDriveAudit, etag }) => {
      const response = await SharedDrivesService.updateFlbSharedDriveSettings(
        customerStore.activeCustomerId,
        sharedDriveId,
        {
          excludeFromDriveAudit,
          etag,
        }
      );
      notificationStore.showToast("updated shared drive settings", "success");
      return response;
    },
    storeId,
    "update settings",
    "Failed to update shared drive settings"
  );

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    getItem,
    updateItem,
    updateFlbSharedDriveSettings,

    // export
    exportData,
  };
});
