import { ref, computed, watch } from "vue";
import {
  useCustomerStore,
  useNotificationStore,
  useUserPreferenceStore,
  useErrorStore,
} from "@/stores";
import { useArrayHelper } from "@/composables/array-helper";
import { log } from "@/components/helpers/logger-helper";

export function useAPIWrapper(
  storeId,
  services,
  returnObjectKey,
  clientSideFiltering = false,
  clientSidePagination = false,
  listServiceParams = [],
  useOrgUnitFilter = null,
  useIncludeSubOrgUnitsFilter = null,
  errorMessages = {}
) {
  const {
    listItemsService,
    getItemService,
    createItemService,
    updateItemService,
    deleteItemService,
    getQueryJobStatusService,
    exportDataService,
    getExportJobStatusService,
  } = services;
  const customerStore = useCustomerStore();
  const notificationStore = useNotificationStore();
  const userPreferenceStore = useUserPreferenceStore();
  const errorStore = useErrorStore();
  const fetchedItems = ref([]); // items fetched from the API (used as a cache of all items before filtering)
  const processedItems = ref(null); // items that are filtered and sorted
  // if there are processed items, return them, otherwise return the fetched items
  const items = ref(
    processedItems.value !== null ? processedItems.value : fetchedItems.value
  );
  const pageToken = ref(null);
  const savedPageSize = ref(null);
  const loading = ref(false);
  const queryJobId = ref(null);
  const totalRows = ref(null);
  const currentPollingId = ref(null);
  const exportId = ref(null);

  function handleMissingService(service, operation) {
    if (!service) {
      const error = new Error(
        `${operation} service is not provided for: ${storeId}`
      );

      log.error(error, {
        userMessage:
          errorMessages[operation] ||
          `There was an error. ${errorStore.defaultErrorMessage}`,
      });
      return false;
    }
    return true;
  }

  function withAPIErrorHandling(fn, operation) {
    return async (...args) => {
      try {
        return await fn(...args);
      } catch (error) {
        const data = {
          body: error.body,
          status: error.status,
          name: error.name,
          request: error.request,
          statusText: error.statusText,
          url: error.url,
          stack: error.stack,
        };

        error.message = `${error.message}: ${
          error.body?.detail || error.body?.error?.detail || ""
        } ${storeId}`;

        log.error(error, {
          ...data,
          userMessage:
            errorMessages[operation] ||
            `There was an error. ${errorStore.defaultErrorMessage}`,
        });

        loading.value = false;
      }
    };
  }

  // ===================== //
  // ======= LIST ======== //
  // ===================== //

  const totalItems = computed(() => {
    if (totalRows.value && totalRows.value > items.value.length) {
      return totalRows.value;
    }
    if (pageToken.value) {
      return null;
    }
    return items.value.length;
  });

  //? ==== LIST FILTERS ====

  const availableFilters = ref([]);
  const activeFilters = ref([]);
  const primaryFilter = null;
  const orgUnitFilter = useOrgUnitFilter;
  const includeSubOrgUnitsFilter = useIncludeSubOrgUnitsFilter;

  const activeAdvancedFilters = computed(() => {
    return activeFilters.value.filter((filter) => filter.primary !== true);
  });

  const advancedFilters = computed(() => {
    // return a deep copy of the available filters that are not the primary filter and update them with the values from the active filters
    return availableFilters.value
      .filter((filter) => filter.primary !== true)
      .map((filter) => {
        const activeFilter = activeFilters.value.find(
          (activeFilter) => activeFilter.id === filter.id
        );
        if (activeFilter) {
          return {
            ...filter,
            value: activeFilter.value,
          };
        }
        return filter;
      });
  });

  const setAvailableFilters = async (filters) => {
    availableFilters.value = filters;
  };

  const addFilter = async (filter) => {
    // when we change a filter we need to reset the params
    resetStore();

    // check if filter already exists and remove it
    removeFilter(filter.id);
    activeFilters.value.push(filter);
  };

  const removeFilter = (filterId) => {
    // when we change a filter we need to reset the params
    resetStore();

    const index = activeFilters.value.findIndex(
      (filter) => filter.id === filterId
    );
    if (index !== -1) {
      activeFilters.value.splice(index, 1);
    }
  };

  const updateFilter = (filter) => {
    // when we change a filter we need to reset the params
    resetStore();

    const index = activeFilters.value.findIndex(
      (activeFilter) => activeFilter.id === filter.id
    );
    // if the filter does not exist and has a value, add it to the activeFilters
    if (index === -1 && filterHasValue(filter)) {
      activeFilters.value.push(filter);
      return;
    }
    // if the filter exists and has no value, remove it from the activeFilters
    if (index !== -1 && !filterHasValue(filter)) {
      activeFilters.value.splice(index, 1);
      return;
    }
    // if the filter exists and has a value, update it in the activeFilters
    if (index !== -1 && filterHasValue(filter)) {
      activeFilters.value[index] = filter;
    }
  };

  // check if filter.value is not the nullValue or empty string
  const filterHasValue = (filter) => {
    return !(
      filter.value === null ||
      filter.value === undefined ||
      filter.value === filter.nullValue ||
      filter.value === "" ||
      // if filter.value is an array, check if it is empty
      (Array.isArray(filter.value) && filter.value.length === 0)
    );
  };

  const clearPageToken = () => {
    pageToken.value = null;
  };
  // Reset the pageToken when filters or org units change
  watch(activeFilters, clearPageToken);

  watch(() => userPreferenceStore.selectedOrgUnit, clearPageToken, {
    deep: true,
  });

  // used to filter items on the client side
  const filterItems = (filters) => {
    if (filters.length > 0) {
      return fetchedItems.value.filter((item) => {
        return filters
          .filter(({ fn }) => fn)
          .every((filter) => {
            return filter.fn(item, filter.value);
          });
      });
    }
    return fetchedItems.value;
  };

  const addOrgUnitsFilters = (filters) => {
    if (orgUnitFilter) {
      filters.push({
        ...orgUnitFilter,
        value: userPreferenceStore.selectedOrgUnit?.orgUnitId || "all",
        operator: "=",
      });
    }

    if (includeSubOrgUnitsFilter) {
      filters.push({
        ...includeSubOrgUnitsFilter,
        value: userPreferenceStore.selectedOrgUnit?.includeSubOrgUnits || false,
        operator: "=",
      });
    }
    return filters;
  };

  const generateFilterParams = (filters) => {
    // if there is an orgUnitFilter, add it to the filters
    filters = addOrgUnitsFilters(filters);

    let processedFilters = [];
    filters.forEach((filter) => {
      let operator = filter.operator;
      let id = filter.id;
      let value = filter.value;

      if (filter.apiConverter) {
        const conversion = filter.apiConverter(filter.value);
        // if conversion is array, loop over it and add each item to processedFilters
        operator = conversion.operator || operator;
        id = conversion.id || filter.id;
        if (Array.isArray(conversion)) {
          conversion.forEach((conv) => {
            value = getValueForFilter(conv, operator);
            processedFilters.push(`${id}${operator}${value}`);
          });
          return;
        } else {
          value = getValueForFilter(conversion, operator);
        }
      } else {
        value = getValueForFilter(filter, operator);
      }

      // if activeFilter has property type and type string add single quotes around the value
      if (filter.property_type && filter.property_type === "string") {
        value = `'${value}'`;
      }

      processedFilters.push(`${id}${operator}${value}`);
    });
    return processedFilters.join("&");
  };

  const getValueFromFilterCondition = (value, filter = {}) => {
    if (filter.apiConverter) {
      return filter.apiConverter(value)?.value ?? value;
    } else if (filter.valueKey) {
      return value[filter.valueKey];
    } else {
      return value;
    }
  };

  const getValueForFilter = (filter, operator) => {
    let value = "";
    if (
      ["IN", "NOTIN", "MATCHES_ALL"].includes(operator) &&
      Array.isArray(filter.value)
    ) {
      const valueArray = [];
      filter.value?.forEach((valueObject) => {
        valueArray.push(getValueFromFilterCondition(valueObject, filter));
      });

      value = `(${valueArray.join("-OR-")})`;
    } else {
      value = getValueFromFilterCondition(filter.value, filter);
    }
    return value;
  };

  //? ==== RESET ====

  const reset = async () => {
    resetStore();
    activeFilters.value = [];
  };

  const resetStore = () => {
    fetchedItems.value = [];
    processedItems.value = null;
    items.value = [];
    pageToken.value = null;
    queryJobId.value = null;
    exportId.value = null;
    totalRows.value = null;
    loading.value = false;
  };

  //? ==== SORT ====

  const sortItems = (sortBy, sortOrder) =>
    useArrayHelper().sort(processedItems.value, sortBy, sortOrder);

  //? ==== POLLING ====

  const pollResults = withAPIErrorHandling(
    async (params, affectItems = true, delay = 1000, pollingId) => {
      if (currentPollingId.value !== pollingId) {
        return;
      }
      loading.value = true;
      const res = await getQueryJobStatusService(
        // TODO params toevoegen
        customerStore.activeCustomerId,
        queryJobId.value,
        params.requiresAll,
        params.pageSize,
        pageToken.value
      );
      if (res && res.queryJobState !== "RUNNING") {
        loading.value = false;
        // The queryJobState is not running anymore
        if (queryJobId.value !== currentPollingId.value) {
          return;
        }

        if (res.queryJobState === "FAILED" || res.queryJobId === null) {
          queryJobId.value = null;

          return null;
          // TODO error handling
        }

        // If the response has a nextPageToken, update the pageToken.
        pageToken.value = res.nextPageToken;

        // If no items or empty, reset items
        if (
          affectItems &&
          (!res[returnObjectKey] || res[returnObjectKey].length === 0)
        ) {
          fetchedItems.value = [];

          return [];
        }

        // If no pageSize, overwrite the items, otherwise append
        if (affectItems) {
          fetchedItems.value = params.pageSize
            ? [...fetchedItems.value, ...res[returnObjectKey]]
            : res[returnObjectKey];
        }

        if (clientSidePagination) {
          clientSideFiltering
            ? (processedItems.value = filterItems(activeFilters))
            : (processedItems.value = fetchedItems.value);
          // sortItems(sortBy, sortOrder);
        }
        if (res.totalRows) {
          totalRows.value = res.totalRows;
        }

        return affectItems ? items.value : res[returnObjectKey];
      } else {
        const newDelay = Math.min(delay * 2, 30000); // double the delay, but max 30 seconds
        setTimeout(
          () => pollResults(params, affectItems, newDelay, pollingId),
          delay
        );
      }
    },
    "pollResults"
  );

  //? ==== POLL EXPORT ====
  const pollExportResults = withAPIErrorHandling(
    async (delay = 1000, exportId) => {
      const exportObj = await getExportJobStatusService(
        customerStore.activeCustomerId,
        exportId
      );

      if (exportObj && ["FAILED", "COMPLETED"].includes(exportObj.status)) {
        if (
          exportObj.status === "FAILED" &&
          exportObj.exceededMaxRows !== true
        ) {
          notificationStore.showToast(
            "Failed to export your files. Please refresh the page and try again later. If the error persists, please contact support@florbs.io",
            "error"
          );

          return null;
        }

        return exportObj;
      } else {
        const newDelay = Math.min(delay * 2, 60000); // double the delay, but max 30 seconds
        await new Promise((resolve) => setTimeout(resolve, delay));
        return pollExportResults(newDelay, exportId);
      }
    },
    "pollExportResults"
  );

  //? ==== LIST ITEMS ====

  const listItems = withAPIErrorHandling(
    async ({
      pageSize = null,
      exportFormat = null,
      sortBy = null,
      requiresAll = null,
      sortOrder = null,
      forceRefresh = false,
      extraArgs = null,
    } = {}) => {
      if (loading.value) {
        return;
      }

      if (!handleMissingService(listItemsService, "listItems")) {
        loading.value = false;
        return;
      }
      loading.value = true;

      if (forceRefresh) {
        fetchedItems.value = [];
        processedItems.value = null;
        queryJobId.value = null;
      }

      if (
        clientSidePagination &&
        fetchedItems.value.length > 0 &&
        !forceRefresh &&
        (clientSideFiltering || activeFilters.value.length === 0)
      ) {
        processedItems.value = filterItems(activeFilters.value);

        sortItems(sortBy, sortOrder);

        loading.value = false;
        return items.value;
      }

      if (pageSize !== savedPageSize.value) {
        pageToken.value = null;
        savedPageSize.value = pageSize;
      }

      const filterParams = generateFilterParams([...activeFilters.value]);
      const query = filterParams !== "" ? filterParams : undefined;
      const params = {
        customerId: customerStore.activeCustomerId,
        ...(exportFormat && { exportFormat }),
        ...(pageSize && { pageSize }),
        ...(requiresAll && { requiresAll }),
        ...(pageToken.value && { pageToken: pageToken.value }),
        ...(query && { query }),
        ...(sortBy && { sortBy }),
        // add sortOrder only if sortBy is present
        ...(sortBy && sortOrder && { sortOrder }),
      };

      if (extraArgs) {
        Object.assign(params, extraArgs);
      }

      // If queryJobId exists, poll for results instead of making a new API call
      if (queryJobId.value) {
        await pollResults(params, true, 1000, currentPollingId.value);
        return;
      }

      // Create an array of arguments in the correct order as expected by the service
      const args = listServiceParams.map((param) => params[param]);

      const res = await listItemsService(...args);

      // If the response has a queryJobId, start polling for results
      if (res?.queryJobId) {
        currentPollingId.value = res.queryJobId;
        queryJobId.value = res.queryJobId;
        await pollResults(params, true, 1000, currentPollingId.value);
        return res;
      }

      // If the response has a nextPageToken, update the pageToken.
      if (res) {
        pageToken.value = res.nextPageToken;
      }

      // If no items or empty, reset items
      if (!res[returnObjectKey] || res[returnObjectKey].length === 0) {
        fetchedItems.value = [];

        loading.value = false;
        return [];
      }

      // If no pageSize, overwrite the items, otherwise append
      fetchedItems.value = pageSize
        ? [...fetchedItems.value, ...res[returnObjectKey]]
        : res[returnObjectKey];

      // If clientSidePagination, apply client-side filtering and sorting
      if (clientSidePagination) {
        clientSideFiltering
          ? (processedItems.value = filterItems(activeFilters.value))
          : (processedItems.value = fetchedItems.value);
        sortItems(sortBy, sortOrder);
      }

      loading.value = false;

      return items.value;
    },
    "listItems"
  );

  // ===================== //
  // ======= EXPORT ====== //
  // ===================== //
  const exportData = withAPIErrorHandling(
    async ({ mapping = null, sortBy = null, sortOrder = null } = {}) => {
      if (!handleMissingService(exportDataService, "exportData")) {
        return;
      }

      const filterParams = generateFilterParams([...activeFilters.value]);
      const query = filterParams !== "" ? filterParams : undefined;
      const params = {
        customerId: customerStore.activeCustomerId,
        exportFormat: "csv",
        exportMapping: JSON.stringify(mapping),
        ...(query && { query }),
        ...(sortBy && { sortBy }),
        // add sortOrder only if sortBy is present
        ...(sortBy && sortOrder && { sortOrder }),
      };

      // Create an array of arguments in the correct order as expected by the service
      const args = listServiceParams.map((param) => params[param]);
      const exportResponse = await exportDataService(...args);

      // If the response has a queryJobId, start polling for results
      if (exportResponse?.export?.id) {
        exportId.value = exportResponse?.export?.id;

        return await pollExportResults(1000, exportId.value);
      }
    },
    "exportData"
  );

  // ===================== //
  // ======= GET ========= //
  // ===================== //

  const getItem = withAPIErrorHandling(
    async (itemId, forceFetch = false, message = {}) => {
      if (!handleMissingService(getItemService, "getItem")) {
        return;
      }

      if (!forceFetch) {
        const existingUser = items.value.find((user) => user.id === itemId);
        if (existingUser) {
          return existingUser;
        }
      }

      const params = {
        customerId: customerStore.activeCustomerId,
      };

      // If queryJobId exists, poll for results instead of making a new API call
      if (queryJobId.value && !forceFetch) {
        return await pollResults(params, false, 1000, queryJobId.value);
      }

      const res = await getItemService(
        customerStore.activeCustomer.customerId,
        itemId
      );

      // If the response has a queryJobId, start polling for results
      if (res?.queryJobId) {
        currentPollingId.value = res.queryJobId;
        queryJobId.value = res.queryJobId;
        await pollResults(params, true, 1000, currentPollingId.value);
        return;
      }

      if (res) {
        const existingUser = items.value.find((user) => user.id === itemId);
        if (existingUser) {
          Object.assign(existingUser, res);
        }
      }

      if (message.success) {
        notificationStore.showToast(message.success, "success");
      }

      return res;
    },
    "getItem"
  );

  // ===================== //
  // ====== CREATE ======= //
  // ===================== //

  const createItem = withAPIErrorHandling(
    async (item, parentId = null, message = {}) => {
      const { activeCustomer } = customerStore;

      if (!handleMissingService(createItemService, "createItem")) {
        return;
      }

      loading.value = true;

      const customerId = activeCustomer.customerId;
      const serviceArgs = parentId
        ? [customerId, parentId, item]
        : [customerId, item];

      const res = await createItemService(...serviceArgs);

      if (res && clientSidePagination && fetchedItems.value.length > 0) {
        // If clientSidePagination is true, append the item to the list
        fetchedItems.value = [res, ...fetchedItems.value];
        processedItems.value = [res, ...processedItems.value];
      } else if (
        res &&
        !clientSidePagination &&
        fetchedItems.value.length > 0
      ) {
        // If clientSidePagination is false, refetch the list
        listItems({ forceRefresh: true });
      }

      if (message.success) {
        notificationStore.showToast(message.success, "success");
      }

      loading.value = false;
      return res;
    },
    "createItem"
  );

  // ===================== //
  // ====== UPDATE ======= //
  // ===================== //

  const updateItem = withAPIErrorHandling(
    async (itemId, item, parentId = null, message = {}) => {
      const { activeCustomer } = customerStore;

      if (!handleMissingService(updateItemService, "updateItem")) {
        return;
      }

      loading.value = true;

      const customerId = activeCustomer.customerId;
      const serviceArgs = parentId
        ? [customerId, parentId, itemId, item]
        : [customerId, itemId, item];

      const res = await updateItemService(...serviceArgs);

      if (res && fetchedItems.value.length > 0) {
        // Update the item in the list
        let index = fetchedItems.value.findIndex((item) => item.id === itemId);
        if (index !== -1) {
          fetchedItems.value[index] = res;
        }
        index =
          processedItems.value?.findIndex((item) => item.id === itemId) ?? -1;
        if (index !== -1) {
          processedItems.value[index] = res;
        }
      }

      if (message.success) {
        notificationStore.showToast(message.success, "success");
      }

      loading.value = false;
      return res;
    },
    "updateItem"
  );

  // ===================== //
  // ====== DELETE ======= //
  // ===================== //

  const deleteItem = withAPIErrorHandling(
    async (itemId, parentId = null, message = {}) => {
      const { activeCustomer } = customerStore;

      if (!handleMissingService(deleteItemService, "deleteItem")) {
        return;
      }

      loading.value = true;

      const customerId = activeCustomer.customerId;
      const serviceArgs = parentId
        ? [customerId, parentId, itemId]
        : [customerId, itemId];

      await deleteItemService(...serviceArgs);

      if (clientSidePagination && fetchedItems.value.length > 0) {
        // Remove the item from the list
        fetchedItems.value = fetchedItems.value.filter(
          (item) => item.id !== itemId
        );
        processedItems.value = processedItems.value.filter(
          (item) => item.id !== itemId
        );
      } else if (!clientSidePagination && fetchedItems.value.length > 0) {
        // If clientSidePagination is false, refetch the list
        listItems({ forceRefresh: true });
      }

      if (message.success) {
        notificationStore.showToast(message.success, "success");
      }

      loading.value = false;
      return true;
    },
    "deleteItem"
  );

  watch(
    [processedItems, fetchedItems],
    () => {
      items.value =
        processedItems.value !== null
          ? processedItems.value
          : fetchedItems.value;
    },
    { deep: true }
  );

  return {
    // General
    loading,
    reset,
    resetStore,

    // filters
    activeFilters,
    updateFilter,
    setAvailableFilters,
    availableFilters,
    primaryFilter,
    advancedFilters,
    activeAdvancedFilters,
    addFilter,
    removeFilter,
    generateFilterParams,

    // List
    items,
    listItems,
    pageToken,
    totalItems,

    // Get
    getItem,

    // Create
    createItem,

    // Update
    updateItem,

    // Delete
    deleteItem,

    // Export
    exportData,
  };
}
