/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ExportFormat } from '../models/ExportFormat';
import type { ExportOrBigqueryJobResponse } from '../models/ExportOrBigqueryJobResponse';
import type { GetSharedDriveResponse } from '../models/GetSharedDriveResponse';
import type { SortOrderEnum } from '../models/SortOrderEnum';
import type { src__helpers__shared_drive_helper__SortByEnum } from '../models/src__helpers__shared_drive_helper__SortByEnum';
import type { UpdateSharedDrive } from '../models/UpdateSharedDrive';
import type { UpdateSharedDriveFlbSettingsRequest } from '../models/UpdateSharedDriveFlbSettingsRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SharedDrivesService {

    /**
     * List Shared drives
     * List shared drives
     * @param customerId
     * @param exportFormat Export data type (csv, json, pdf)
     * @param exportMapping Export mapping as JSON string
     * @param query
     * Allowed operators:<br>=, ==, !=, <=, >=, <, >, IN, LIKE.<br>
     * (LIKE is by default %value%. Use '%' at the beginning or end of the value to change the position of the wildcard.)
     * <br><br>
     * Example query:<br>id_fieldIN(id1ORid2),field=value,struct_field.struct_param=value,repeated_struct_field.struct_param=value,fieldLIKEvalue<br>
     *
     * @param sortBy
     * @param sortOrder
     * @returns ExportOrBigqueryJobResponse Successful Response
     * @throws ApiError
     */
    public static listSharedDrives(
        customerId: string,
        exportFormat?: ExportFormat,
        exportMapping?: string,
        query?: string,
        sortBy?: src__helpers__shared_drive_helper__SortByEnum,
        sortOrder?: SortOrderEnum,
    ): CancelablePromise<ExportOrBigqueryJobResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/shared-drives',
            path: {
                'customer_id': customerId,
            },
            query: {
                'export_format': exportFormat,
                'export_mapping': exportMapping,
                'query': query,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get stored and realtime shared drive
     * Get stored and realtime shared drive
     * @param customerId
     * @param sharedDriveId
     * @returns GetSharedDriveResponse Successful Response
     * @throws ApiError
     */
    public static getSharedDrive(
        customerId: string,
        sharedDriveId: string,
    ): CancelablePromise<GetSharedDriveResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/shared-drives/{shared_drive_id}',
            path: {
                'customer_id': customerId,
                'shared_drive_id': sharedDriveId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update shared drive
     * Update stored shared drive
     * @param customerId
     * @param sharedDriveId
     * @param requestBody
     * @returns GetSharedDriveResponse Successful Response
     * @throws ApiError
     */
    public static updateSharedDrive(
        customerId: string,
        sharedDriveId: string,
        requestBody: UpdateSharedDrive,
    ): CancelablePromise<GetSharedDriveResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/security-auditing/{customer_id}/shared-drives/{shared_drive_id}',
            path: {
                'customer_id': customerId,
                'shared_drive_id': sharedDriveId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update flb shared drive settings
     * Update flb shared drive settings
     * @param customerId
     * @param sharedDriveId
     * @param requestBody
     * @returns GetSharedDriveResponse Successful Response
     * @throws ApiError
     */
    public static updateFlbSharedDriveSettings(
        customerId: string,
        sharedDriveId: string,
        requestBody: UpdateSharedDriveFlbSettingsRequest,
    ): CancelablePromise<GetSharedDriveResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/security-auditing/{customer_id}/shared-drives/update-flb-shared-drive-settings/{shared_drive_id}',
            path: {
                'customer_id': customerId,
                'shared_drive_id': sharedDriveId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
