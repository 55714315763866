/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * CustomerStatus - a model defined in OpenAPI
 */
export enum CustomerStatus {
    FREEMIUM = 'FREEMIUM',
    ONE_TIME_SCAN = 'ONE_TIME_SCAN',
    PAID = 'PAID',
    DEMO = 'DEMO',
    TRIAL = 'TRIAL',
    CANCELLED = 'CANCELLED',
    SUSPENDED = 'SUSPENDED',
    BLOCKED = 'BLOCKED',
}
