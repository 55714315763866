import { defineStore } from "pinia";
import { DriveFilesService } from "@/apis/security-auditing/services/DriveFilesService";
import { BigQueryJobsService } from "@/apis/security-auditing/services/BigQueryJobsService";
import { ExportService } from "@/apis/security-auditing/services/ExportService";
import { useAPIWrapper } from "@/composables/api-wrapper";
import { computed } from "vue";
import { log } from "@/components/helpers/logger-helper";
import { useErrorStore, useCustomerStore } from "@/stores";
import { useFileDriveHelper } from "@/composables/file-drive-helper";

const storeId = "security-auditing-file-store";

export const useFileStore = defineStore(storeId, () => {
  // ===================== //
  // ======= INIT ======== //
  // ===================== //
  const errorStore = useErrorStore();
  const customerStore = useCustomerStore();
  const services = {
    listItemsService: DriveFilesService.listFiles,
    getItemService: DriveFilesService.getFile,
    updateItemService: DriveFilesService.updateFile,
    getQueryJobStatusService: BigQueryJobsService.pollJobResult,
    exportDataService: DriveFilesService.listFiles,
    getExportJobStatusService: ExportService.getExport,
  };

  const errorMessages = {
    listItems: `Failed to retrieve your files. ${errorStore.defaultErrorMessage}`,
    getItem: `Failed to retrieve file. ${errorStore.defaultErrorMessage}`,
    updateItem: `Failed to update file. ${errorStore.defaultErrorMessage}`,
    getQueryJobStatus: `Failed to retrieve your files. ${errorStore.defaultErrorMessage}`,
    exportData: `Failed to export your files. ${errorStore.defaultErrorMessage}`,
  };

  const returnObjectKey = "rows";

  const listServiceParams = [
    "customerId",
    "exportFormat",
    "exportMapping",
    "query",
    "sortBy",
    "sortOrder",
  ];

  // set the values to determine if the filtering and pagination should be done on the client or server side
  const clientSideFiltering = false;
  const clientSidePagination = false;

  // orgUnitFilter is used to determine if the orgUnit filter should be used
  const useOrgUnitFilter = {
    id: "orgUnit.orgUnitId",
  };

  const useIncludeOrgUnitsFilter = {
    id: "includeSubOrgUnits",
  };

  const activeCustomerId = computed(() => {
    return localStorage.getItem("customerId");
  });

  const getFileUrl = async (fileId) =>
    await DriveFilesService.getFileUrl(customerStore.activeCustomerId, fileId);

  const getItem = errorStore.withErrorHandling(
    async (fileId) => {
      const file = await services.getItemService(
        customerStore.activeCustomerId,
        fileId
      );

      // merge file realtimeFile and storedFile
      const fileDetails = file.realtimeFile || {
        realTimeFileNotRetrievable: true,
      };

      // add missing properties from storedFile
      Object.keys(file.storedFile).forEach((key) => {
        if (!fileDetails[key] && file.storedFile[key]) {
          fileDetails[key] = file.storedFile[key];
        }
      });

      // loop through fileDetails.userProperties check if fileDetails.permissions in fileDetails.userProperties.permission
      // overwrite fileDetails.userProperties.permission with entry in fileDetails.permissions array if id matches
      // so make sure we always have the latest permission data icm with the userProperties
      let removedPermissionIds = [];
      if (!fileDetails.permissions) {
        fileDetails.permissions = [];
      }

      if (!file.realtimeFile) {
        fileDetails.userProperties.forEach((userProp) => {
          fileDetails.permissions.push(userProp.permission);
        });
      }

      if (fileDetails.userProperties && file.realtimeFile) {
        fileDetails.userProperties.forEach((userProp, index) => {
          const permissionIndex = fileDetails.permissions.findIndex(
            (p) => p.id === userProp.permission.id
          );

          if (permissionIndex !== -1) {
            fileDetails.userProperties[index].permission =
              fileDetails.permissions[permissionIndex];
          } else {
            removedPermissionIds.push(userProp.permission.id);
          }
        });

        // remove permissions that are not in the permissions array
        fileDetails.userProperties = fileDetails.userProperties.filter(
          (userProp) => !removedPermissionIds.includes(userProp.permission.id)
        );
      }

      // add missing properties from permissions to userProperties
      if (fileDetails.permissions) {
        fileDetails.permissions.forEach((permission) => {
          const userPropIndex = fileDetails.userProperties.findIndex(
            (userProp) => userProp.permission.id === permission.id
          );

          if (userPropIndex === -1) {
            fileDetails.userProperties.push({
              fileId: fileDetails.id,
              facts: {}, // TODO: we have a separate api call get this information from the backend, ignored for now
              permission: permission,
            });
          }
        });
      }

      return fileDetails;
    },
    storeId,
    "get",
    "Failed to retrieve file"
  );

  // use the API wrapper composable to handle filtering, sorting, pagination and listing of items
  const {
    // filters
    activeFilters,
    activeAdvancedFilters,
    clearAllFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    resetFilters,
    generateFilterParams,
    // list
    items,
    listItems,
    totalItems,
    pageToken,
    // general
    resetStore,
    reset,
    loading,
    // crud
    // getItem,

    // exports
    exportData,
  } = useAPIWrapper(
    storeId,
    services,
    returnObjectKey,
    clientSideFiltering,
    clientSidePagination,
    listServiceParams,
    useOrgUnitFilter,
    useIncludeOrgUnitsFilter,
    errorMessages
  );

  const updateItem = async (fileId, body) => {
    try {
      return services.updateItemService(activeCustomerId.value, fileId, body);
    } catch (error) {
      log.error(error, {
        userMessage: `Failed to update file. ${errorStore.defaultErrorMessage}`,
        storeId: storeId,
      });
    }
  };

  const hiddenFilters = useFileDriveHelper().hiddenFilters;

  return {
    // filters
    activeFilters,
    useOrgUnitFilter,
    activeAdvancedFilters,
    clearAllFilters,
    hiddenFilters,
    addFilter,
    setAvailableFilters,
    availableFilters,
    advancedFilters,
    removeFilter,
    updateFilter,
    resetFilters,
    generateFilterParams,

    // list
    items,
    listItems,
    totalItems,
    pageToken,
    clientSidePagination,

    // general
    resetStore,
    reset,
    loading,

    // crud
    getItem,
    updateItem,
    getFileUrl,

    // export
    exportData,
  };
});
