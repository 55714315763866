/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTrustedSource } from '../models/CreateTrustedSource';
import type { PageSizeEnum } from '../models/PageSizeEnum';
import type { SortOrderEnum } from '../models/SortOrderEnum';
import type { src__helpers__trusted_source_helper__SortByEnum } from '../models/src__helpers__trusted_source_helper__SortByEnum';
import type { src__models__drive_models__Permission } from '../models/src__models__drive_models__Permission';
import type { TrustedSource } from '../models/TrustedSource';
import type { TrustedSourceListResponse } from '../models/TrustedSourceListResponse';
import type { TrustedSourceUpdateRequest } from '../models/TrustedSourceUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TrustedSourceService {

    /**
     * list trusted source
     * Get trusted source
     * @param customerId
     * @param pageSize
     * @param pageToken
     * @param sortBy
     * @param sortOrder
     * @returns TrustedSourceListResponse Successful Response
     * @throws ApiError
     */
    public static listTrustedSources(
        customerId: string,
        pageSize?: PageSizeEnum,
        pageToken?: string,
        sortBy?: src__helpers__trusted_source_helper__SortByEnum,
        sortOrder?: SortOrderEnum,
    ): CancelablePromise<TrustedSourceListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/trusted-sources',
            path: {
                'customer_id': customerId,
            },
            query: {
                'pageSize': pageSize,
                'pageToken': pageToken,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create trusted source
     * Create trusted_source
     * @param customerId
     * @param requestBody
     * @returns TrustedSource Successful Response
     * @throws ApiError
     */
    public static createTrustedSource(
        customerId: string,
        requestBody: CreateTrustedSource,
    ): CancelablePromise<TrustedSource> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/trusted-sources',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get trusted source by id
     * Get trusted_source by id
     * @param customerId
     * @param trustedSourceId
     * @returns TrustedSource Successful Response
     * @throws ApiError
     */
    public static getTrustedSource(
        customerId: string,
        trustedSourceId: number,
    ): CancelablePromise<TrustedSource> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/security-auditing/{customer_id}/trusted-sources/{trusted_source_id}',
            path: {
                'customer_id': customerId,
                'trusted_source_id': trustedSourceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete trusted source
     * Delete trusted_source
     * @param customerId
     * @param trustedSourceId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteTrustedSource(
        customerId: string,
        trustedSourceId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/security-auditing/{customer_id}/trusted-sources/{trusted_source_id}',
            path: {
                'customer_id': customerId,
                'trusted_source_id': trustedSourceId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update trusted source
     * Update trusted source
     * @param customerId
     * @param trustedSourceId
     * @param requestBody
     * @returns TrustedSource Successful Response
     * @throws ApiError
     */
    public static updateTrustedSource(
        customerId: string,
        trustedSourceId: number,
        requestBody?: TrustedSourceUpdateRequest,
    ): CancelablePromise<TrustedSource> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/security-auditing/{customer_id}/trusted-sources/{trusted_source_id}',
            path: {
                'customer_id': customerId,
                'trusted_source_id': trustedSourceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get permissions tags
     * Get permissions tags
     * @param customerId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getPermissionsTags(
        customerId: string,
        requestBody: Array<src__models__drive_models__Permission>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/security-auditing/{customer_id}/trusted-sources/get-permissions-tags',
            path: {
                'customer_id': customerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                409: `Conflict - Resource outdated`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
