<template>
  <button
    class="flex text-sm w-full h-fit"
    @click="active ? onClick() : null"
    :disabled="props.menuItem.disabled"
  >
    <!-- Conditionally render the VTooltip component -->
    <VTooltip v-if="props.menuItem.tooltip">
      <VRow
        gap="0"
        :class="[
          props.active && !props.menuItem.disabled
            ? 'bg-google-blue-100 text-google-blue-800'
            : 'text-black',
          'py-2 px-3 items-start text-nowrap w-full select-none',
        ]"
      >
        <div v-if="props.menuItem.icon" class="my-auto mr-3">
          <VIcon
            :class="{ 'text-gray-75': props.menuItem.disabled }"
            class="items-center"
            :name="props.menuItem.icon"
            small
          />
        </div>
        <span
          :class="{ 'text-gray-75': props.menuItem.disabled }"
          class="text-left"
        >
          {{ props.menuItem.name }}</span
        >
      </VRow>
      <template #tooltip>
        {{ props.menuItem.tooltip }}
      </template>
    </VTooltip>
    <!-- Render the content without tooltip if tooltip is empty -->
    <VRow
      v-else
      gap="0"
      :class="[
        props.active && !props.menuItem.disabled
          ? 'bg-google-blue-100 text-google-blue-800'
          : 'text-black',
        'py-2 px-3 items-center text-nowrap w-full select-none',
      ]"
    >
      <div v-if="props.menuItem.icon" class="mr-3">
        <VIcon
          :class="{ 'text-gray-75': props.menuItem.disabled }"
          class="items-center"
          :name="props.menuItem.icon"
          small
        />
      </div>
      <span
        :class="{ 'text-gray-75': props.menuItem.disabled }"
        class="text-left"
        >{{ props.menuItem.name }}</span
      >
    </VRow>
  </button>
</template>

<script setup>
const props = defineProps({
  menuItem: {
    type: Object,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
  item: {
    type: Object,
    default: null,
  },
});

const onClick = () =>
  props.item ? props.menuItem.action(props.item) : props.menuItem.action();
</script>
